import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import { contratoStore, mainStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import _ from 'lodash';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import LivroSolicitadoDiretoFranchiseButton from '@/components/LivroSolicitadoDiretoFranchiseButton.vue';
import AulaLocalShowButton from '@/components/AulaLocalShowButton.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
let Rematricula = class Rematricula extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Assinatura do contrato', value: 'vigencia_inicio' },
            { text: 'Status do contrato', value: 'status_id' },
            { text: 'Previsão fim contrato', value: 'vigencia_fim_previsao' },
            { text: 'Consultor', value: 'consultor_nome' },
            { text: 'Curso', value: 'curso_nome' },
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Responsável', value: 'responsavel_nome' },
            { text: 'Contatos', value: 'aluno_contatos' },
            { text: 'Como conheceu', value: 'como_conheceu_knn' },
            {
                text: 'Primeira parcela do curso',
                value: 'curso_primeira_parcela_status_id',
            },
            { text: 'Taxa de Matricula', value: 'taxa_matricula_valor' },
            {
                text: 'Primeira parcela do material',
                value: 'material_didatico_primeira_parcela_status_id',
            },
            {
                text: 'Forma pagamento',
                value: 'material_didatico_forma_pagamento',
            },
            { text: 'Desconto', value: 'curso_desconto_percentual' },
            { text: 'Turma', value: 'turma_id' },
        ];
        this.headersName = [
            { text: 'Nome', value: 'name' },
            { text: 'Qtd Matrículas', value: 'value' },
        ];
        this.headersTotalizadores = [
            ...this.headersName,
            { text: '1ª Parc. Em aberto', value: 'em_aberto' },
            { text: '1ª Parc. Paga', value: 'pago' },
            { text: '1ª Parc. Cancelada', value: 'cancelado' },
            { text: '1ª Parc. Renegociada', value: 'renegociado' },
            { text: '1ª Parc. Protestada', value: 'protestado' },
            { text: 'Taxa de Matricula', value: 'taxa_matricula_valor' },
        ];
        this.headerListaAlunosRematricular = [
            { text: '', value: 'numero' },
            { text: 'Status do contrato', value: 'status_id' },
            { text: 'Nome do aluno', value: 'aluno_nome' },
            { text: 'Professor', value: 'professor_nome' },
            { text: 'Livro', value: 'livro_nome' },
            { text: 'Previsão término contrato', value: 'vigencia_fim_previsao' },
            { text: 'É última turma', value: 'is_ultima_turma' },
        ];
        this.headerTotalizadorAlunosRematricular = [
            { text: 'Professor', value: 'professor_nome' },
            { text: 'Quantidade Alunos', value: 'quantidade_alunos' },
        ];
        this.search = '';
        this.tabelaRef = null;
        this.loading = false;
        this.items = [];
        this.selectedConsultor = [];
        this.selectedStatus = [];
        this.selectPeriodDate = null;
        this.totais = {};
        this.totaisStatus = {};
        this.tab = 0;
        this.tipoFiltro = 'C';
        this.listaAlunosRematricular = [];
    }
    onChange() {
        this.items = [];
    }
    get listaTotalizadorAlunosRematricular() {
        if (!this.listaAlunosRematricular ||
            this.listaAlunosRematricular.length === 0) {
            return [];
        }
        return _(this.listaAlunosRematricular)
            .groupBy('professor_nome')
            .map((item, id) => ({
            professor_nome: id,
            quantidade_alunos: item.length,
        }))
            .orderBy('professor_nome', 'asc')
            .value();
    }
    get listaFilterAlunosRematricular() {
        const data = [];
        const item = this.listaAlunosRematricular;
        item.forEach((i) => {
            let valid = true;
            if (!this.selectedStatus
                .map((filterData) => parseInt(filterData.name, 0))
                .includes(i.status_id) &&
                !(this.selectedStatus
                    .map((filterData) => filterData.name)
                    .includes('Sem contrato') && !i.status_id)) {
                valid = false;
            }
            if (valid) {
                data.push(i);
            }
        });
        return data;
    }
    get filteredItems() {
        const dataToReturn = [];
        this.items.forEach((i) => {
            let valid = true;
            if (!this.selectedConsultor
                .map((filterData) => filterData.name)
                .includes(i.consultor_nome) &&
                !(this.selectedConsultor
                    .map((filterData) => filterData.name)
                    .includes('Não informado') && !i.consultor_nome)) {
                valid = false;
            }
            if (valid) {
                dataToReturn.push(i);
            }
        });
        return dataToReturn;
    }
    get totalPorConsultor() {
        const countedByName = _(this.items).countBy('consultor_nome').value();
        return Object.keys(countedByName).map((i) => {
            // const consultor = i;
            // usar somente se habilitar o card comentado
            // const total = _.sumBy(this.items, (i) => {
            //   if (i.consultor_nome == consultor) {
            //     return 1
            //   } else {
            //     return 0
            //   }
            // })
            this.totais = {};
            _.forEach(this.items, (item) => {
                let consultor_nome = item.consultor_nome;
                if (consultor_nome == null) {
                    consultor_nome = 'Não informado';
                }
                if (!this.totais[consultor_nome]) {
                    this.totais[consultor_nome] = {};
                    this.totais[consultor_nome][0] = 0;
                    this.totais[consultor_nome][1] = 0;
                    this.totais[consultor_nome][2] = 0;
                    this.totais[consultor_nome][3] = 0;
                    this.totais[consultor_nome][4] = 0;
                    this.totais[consultor_nome].taxa_matricula_valor = 0;
                }
                this.totais[consultor_nome][item.curso_primeira_parcela_status_id]++;
                // Adiciona a taxa de matrícula ao total, se não for "Isento"
                if (item.taxa_matricula_valor && item.taxa_matricula_valor !== 'Isento') {
                    this.totais[consultor_nome].taxa_matricula_valor += parseFloat(item.taxa_matricula_valor) || 0;
                }
            });
            if (i != 'null') {
                return { name: i, value: countedByName[i] };
            }
            else {
                return { name: 'Não informado', value: countedByName[i] };
            }
        });
    }
    get totalPorStatus() {
        const item = this.listaAlunosRematricular;
        const countedByStatus = _(item).countBy('status_id').value();
        return Object.keys(countedByStatus).map((i) => {
            this.totaisStatus = {};
            _.forEach(item, (item) => {
                let status_id = item.status_id;
                if (status_id == null) {
                    status_id = 'Sem contrato';
                }
                if (!this.totaisStatus[status_id]) {
                    this.totaisStatus[status_id] = {};
                    this.totaisStatus[status_id][0] = 0;
                    this.totaisStatus[status_id][1] = 0;
                    this.totaisStatus[status_id][2] = 0;
                    this.totaisStatus[status_id][3] = 0;
                    this.totaisStatus[status_id][4] = 0;
                }
                this.totaisStatus[status_id][item.curso_primeira_parcela_status_id]++;
            });
            if (i != 'null') {
                return { name: i, value: countedByStatus[i] };
            }
            else {
                return { name: 'Sem contrato', value: countedByStatus[i] };
            }
        });
    }
    get totalPorStatusPrimeiraParcela() {
        const countedByName = _(this.items)
            .countBy('curso_primeira_parcela_status_id')
            .value();
        return Object.keys(countedByName).map((i) => {
            if (i != 'null') {
                return { name: i, value: countedByName[i] };
            }
            else {
                return { name: 'Não informado', value: countedByName[i] };
            }
        });
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async buscar() {
        if (!this.selectPeriodDate[0] || !this.selectPeriodDate[1]) {
            return;
        }
        this.loading = true;
        if (this.tipoFiltro === 'C') {
            const filtros = [
                `contrato.vigencia_inicio BETWEEN '${this.selectPeriodDate[0]}' AND '${this.selectPeriodDate[1]}'`,
                'contrato.matricula_tipo_id = 2',
            ];
            this.items = await contratoStore.getContratos(filtros);
        }
        else if (this.tipoFiltro === 'P') {
            const filtros = [
                'POR DATA PAGAMENTO',
                ` BETWEEN '${this.selectPeriodDate[0]}' AND '${this.selectPeriodDate[1]}'`,
                'contrato.matricula_tipo_id = 2',
            ];
            this.items = await contratoStore.getContratos(filtros);
        }
        this.selectedConsultor = this.totalPorConsultor;
        this.loading = false;
    }
    async buscarAlunosParaRematricular() {
        this.loading = true;
        this.listaAlunosRematricular =
            await contratoStore.getContratosAlunosRematricular({
                dataInicial: this.selectPeriodDate[0],
                dataFinal: this.selectPeriodDate[1],
            });
        this.selectedStatus = this.totalPorStatus;
        setTimeout(() => {
            this.tabelaRef = this.$refs.tabela;
        }, 1000);
        this.loading = false;
    }
    mounted() {
        if (this.userProfile.company.aula_online_allow) {
            this.headers.push({
                text: 'Local aula',
                value: 'local_aula_id',
            });
            this.headerListaAlunosRematricular.push({
                text: 'Local aula',
                value: 'local_aula_id',
            });
        }
    }
};
__decorate([
    Watch('tab')
], Rematricula.prototype, "onChange", null);
Rematricula = __decorate([
    Component({
        components: {
            EntidadeLinkComponent,
            LivroSolicitadoDiretoFranchiseButton,
            OpenNewTabComponent,
            PeriodSelectorComponent,
            TextExpandableComponent,
            AulaLocalShowButton,
            ImprimirTabelaComponent,
        },
    })
], Rematricula);
export default Rematricula;
